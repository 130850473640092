import { defineStore } from 'pinia';
import type { Page } from './types';

export const usePageStore = defineStore('page-store', {
  state: (): Page => ({
    activeSection: 0,
    targetSection: false,
    scrollWidth: 0,
    isScrollBlocked: false,
    isMobile: false,
    isNavVisible: false,
    isNavHidden: true,
    isScrolling: false,
    isPopupVisible: false,
    isPromo2Visible: false,
    isEdasvashegostolaVisible: false,
    isSecondPage: false,
    isReviewPage: false,
    isRationPage: false,
    isGyro: false,
    isNavScroll: false,
    isMainCatLoaded: false,
    isShowPopup: false,
    isLoading: false,
    urlPrivacy:
      process.env.DOC_URL
      + 'polzovatelskoe-soglashenie-2021-kitekat.pdf',
  }),
});
